import { GlobalActions } from "./types";
import {
  ERROR_MESSAGE,
  UPDATE_APP_STATUS,
  UPDATE_AUTH_TOKEN,
  UPDATE_AUTH_USER,
  UPDATE_GLOBAL_MODAL,
  UPDATE_WEBSOCKET_CLIENT,
} from "../../sagas/global/types";
import React from "react";

interface IGlobalState {
  errorMessage: string;
  appStatus: string;
  authTokenExp: number;
  authUser: any;
  modal: {
    isOpen: boolean;
    close?: (dispatch: any) => void;
    confirm?: () => void;
    title?: string;
    body?: React.ReactNode;
    isInfo?: boolean;
    closebtnText?: string;
    savebtnText?: string;
  };
  websocketClient: any;
}

const globalState: IGlobalState = {
  errorMessage: "",
  appStatus: "enabled",
  authTokenExp: 0,
  authUser: undefined,
  modal: {
    isOpen: false,
  },
  websocketClient: undefined,
};

const globalReducer = (
  state = globalState,
  action: GlobalActions
): IGlobalState => {
  switch (action.type) {
    case ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload.message,
      };
    case UPDATE_APP_STATUS:
      return {
        ...state,
        appStatus: action.payload.appStatus,
      };
    case UPDATE_AUTH_TOKEN:
      return {
        ...state,
        authTokenExp: action.payload.authTokenExp,
      };
    case UPDATE_AUTH_USER:
      return {
        ...state,
        authUser: action.payload.authUser,
      };
    case UPDATE_GLOBAL_MODAL:
      return {
        ...state,
        modal: {
          ...action.payload,
        },
      };
    case UPDATE_WEBSOCKET_CLIENT:
      return {
        ...state,
        websocketClient: action.payload.websocketClient,
      };
    default:
      return state;
  }
};

export default globalReducer;
