import React from 'react';
import {
    UPDATE_APP_STATUS, UPDATE_AUTH_TOKEN, ERROR_MESSAGE, UPDATE_AUTH_USER,
    UPDATE_GLOBAL_MODAL, UPDATE_WEBSOCKET_CLIENT
} from '../../sagas/global/types'

export interface IDisplayErrorMessageAction {
    readonly type: typeof ERROR_MESSAGE;
    payload: {
        message: string
    }
}

export const displayErrorMessageProps = (payload: IDisplayErrorMessageAction['payload']): IDisplayErrorMessageAction => {
    return {
        type: ERROR_MESSAGE,
        payload: payload
    }
};

export interface IUpdateAppStatusAction {
    readonly type: typeof UPDATE_APP_STATUS;
    payload: {
        appStatus: 'enabled' | 'disabled'
    }
}

export const updateStatusProps = (payload: IUpdateAppStatusAction['payload']): IUpdateAppStatusAction => {
    return {
        type: UPDATE_APP_STATUS,
        payload: payload
    }
};

export interface IUpdateAuthTokenExpAction {
    readonly type: typeof UPDATE_AUTH_TOKEN;
    payload: {
        authTokenExp: number
    }
}

export const updateAuthTokenExpProps = (payload: IUpdateAuthTokenExpAction['payload']): IUpdateAuthTokenExpAction => {
    return {
        type: UPDATE_AUTH_TOKEN,
        payload: payload
    }
};

export interface IUpdateAuthUserAction {
    readonly type: typeof UPDATE_AUTH_USER;
    payload: {
        authUser: any
    }
}

export const updateAuthUserProps = (payload: IUpdateAuthUserAction['payload']): IUpdateAuthUserAction => {
    return {
        type: UPDATE_AUTH_USER,
        payload: payload
    }
};

export interface IUpdateGlobalModalAction {
    readonly type: typeof UPDATE_GLOBAL_MODAL;
    payload: {
        isOpen: boolean,
        close?: (dispatch: any) => void,
        confirm?: () => void,
        title?: string,
        body?: React.ReactNode,
        isInfo?: boolean,
        closebtnText?: string
        savebtnText?: string
    }
}

export const updateGlobalModalProps = (payload: IUpdateGlobalModalAction['payload']): IUpdateGlobalModalAction => {
    return {
        type: UPDATE_GLOBAL_MODAL,
        payload: payload
    }
};

export interface IUpdateWebsocketClientAction {
    readonly type: typeof UPDATE_WEBSOCKET_CLIENT;
    payload: {
        websocketClient: any,
    }
}

export const updateWebsocketClientProps = (payload: IUpdateWebsocketClientAction['payload']): IUpdateWebsocketClientAction => {
    return {
        type: UPDATE_WEBSOCKET_CLIENT,
        payload: payload
    }
};

export type GlobalActions = IDisplayErrorMessageAction | IUpdateAppStatusAction | IUpdateAuthTokenExpAction
 | IUpdateAuthUserAction | IUpdateGlobalModalAction | IUpdateWebsocketClientAction;
