import React from "react";

export const ERROR_MESSAGE = 'ERROR_MESSAGE';

export const UPDATE_APP_STATUS_SAGA = 'UPDATE_APP_STATUS_SAGA';
export const UPDATE_APP_STATUS = 'UPDATE_APP_STATUS';
export interface IUpdateAppStatusActionSaga {
    readonly type: typeof UPDATE_APP_STATUS_SAGA;
    payload: {
        appStatus: 'enabled' | 'disabled'
    }
}

export const updateAppStatusPropsSaga = (payload: IUpdateAppStatusActionSaga['payload']): IUpdateAppStatusActionSaga => {
    return {
        type: UPDATE_APP_STATUS_SAGA,
        payload: payload
    }
};

export const UPDATE_AUTH_TOKEN_SAGA = 'UPDATE_AUTH_TOKEN_SAGA';
export const UPDATE_AUTH_TOKEN = 'UPDATE_AUTH_TOKEN';
export interface IUpdateAuthTokenActionSAGA {
    readonly type: typeof UPDATE_AUTH_TOKEN_SAGA;
    payload: {
        authTokenExp: number
    }
}

export const updateAuthTokenPropsSaga = (payload: IUpdateAuthTokenActionSAGA['payload']): IUpdateAuthTokenActionSAGA => {
    return {
        type: UPDATE_AUTH_TOKEN_SAGA,
        payload: payload
    }
};

export const UPDATE_AUTH_USER_SAGA = 'UPDATE_AUTH_USER_SAGA';
export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER';
export interface IUpdateAuthUserActionSAGA {
    readonly type: typeof UPDATE_AUTH_USER_SAGA;
    payload: {
        authWithKeycloak: boolean
    }
}

export const updateAuthUserPropsSaga = (payload: IUpdateAuthUserActionSAGA['payload']): IUpdateAuthUserActionSAGA => {
    return {
        type: UPDATE_AUTH_USER_SAGA,
        payload: payload
    }
};

export const LOGIN_AUTH_USER_SAGA = 'LOGIN_AUTH_USER_SAGA';
export const LOGIN_AUTH_USER = 'LOGIN_AUTH_USER';
export interface ILoginAuthUserActionSAGA {
    readonly type: typeof LOGIN_AUTH_USER_SAGA;
    payload: {
        email: string,
        password: string
    }
}

export const loginAuthUserPropsSaga = (payload: ILoginAuthUserActionSAGA['payload']): ILoginAuthUserActionSAGA => {
    return {
        type: LOGIN_AUTH_USER_SAGA,
        payload: payload
    }
};

export const UPDATE_GLOBAL_MODAL_SAGA = 'UPDATE_GLOBAL_MODAL_SAGA';
export const UPDATE_GLOBAL_MODAL = 'UPDATE_GLOBAL_MODAL';
export interface IUpdateGlobalModalActionSAGA {
    readonly type: typeof UPDATE_GLOBAL_MODAL_SAGA;
    payload: {
        isOpen: boolean,
        close?: (dispatch: any) => void,
        confirm?: () => void,
        title?: string,
        body?: React.ReactNode,
        isInfo?: boolean,
        closebtnText?: string
        savebtnText?: string
    }
}

export const updateGlobalModalPropsSaga = (payload: IUpdateGlobalModalActionSAGA['payload']): IUpdateGlobalModalActionSAGA => {
    return {
        type: UPDATE_GLOBAL_MODAL_SAGA,
        payload: payload
    }
};

export const UPDATE_WEBSOCKET_CLIENT_SAGA = 'UPDATE_WEBSOCKET_CLIENT_SAGA';
export const UPDATE_WEBSOCKET_CLIENT = 'UPDATE_WEBSOCKET_CLIENT';
export interface IUpdateWebsocketClientActionSAGA {
    readonly type: typeof UPDATE_WEBSOCKET_CLIENT_SAGA;
    payload: {
        websocketClient: any,
    }
}

export const updateWebsocketClientPropsSaga = (payload: IUpdateWebsocketClientActionSAGA['payload']): IUpdateWebsocketClientActionSAGA => {
    return {
        type: UPDATE_WEBSOCKET_CLIENT_SAGA,
        payload: payload
    }
};